::selection {
    background: transparent;
    color: green;
}

::-webkit-selection {
    background: transparent;
    color: green;
}

.blinking-cursor {
    display: inline-block;
    width: 3px;
    height: 1.2em;
    background-color: white;
    animation: infinite;
    margin-left: 5px;
    vertical-align: middle;
}